import { createI18n } from 'vue-i18n';
import { nextTick } from 'vue';
import en from '@/locales/default.json';
import languages from '@/locales/languages.json';

export const defaultLocale = 'en-US';

const datetimeFormats = {
  'en-US': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
};

export function setupI18n(
  options = {
    legacy: false,
    locale: defaultLocale,
  },
) {
  return createI18n(options);
}

export async function loadLocaleMessages(i18n, locale) {
  if (!Object.keys(languages).includes(locale)) {
    locale = 'default';
  }
  if (locale === 'en-US') locale = 'default';

  try {
    // load locale messages with dynamic import
    const messages = await import(
      /* webpackChunkName: "locale-[request]" */ `../locales/${locale}.json`
    );

    // set locale and locale message
    i18n.global.setLocaleMessage(locale, messages.default);
  } catch (e) {
    console.log(e);
  }

  return nextTick();
}

const i18n = setupI18n({
  legacy: false,
  locale: defaultLocale,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  datetimeFormats,
  messages: { 'en-US': en },
  fallbackLocale: 'en-US',
});

export default i18n;
