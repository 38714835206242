export default {
  injected: {
    id: 'injected',
    name: 'MetaMask',
    icon: '/images/connectors/metamask.svg',
    link: 'https://metamask.io/',
    options: {},
  },
  // walletconnect: {
  //   id: 'walletconnect',
  //   name: 'WalletConnect',
  //   network: 'testnet',
  //   chainId: 200101,
  //   icon: '/images/connectors/walletconnect.svg',
  //   options: {
  //     rpcUrl: 'https://use-util.cloud.milkomeda.com:8555',
  //     network: 'testnet',
  //     chainId: 200101,
  //     rpc: {
  //       '200101': 'https://use-util.cloud.milkomeda.com:8555',
  //     },
  //   },
  //   detect: ['isMobile', 'isBrowser'],
  // },
};
