import { createPinia } from 'pinia';
(window as any).global = window;
import { walletsOptions } from '@/helpers/wallet-options';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import PrimeVue from 'primevue/config';
import i18n from '@/helpers/i18n';
import Button from 'primevue/button';
import OverlayPanel from 'primevue/overlaypanel';
import ProgressBar from 'primevue/progressbar';
import Skeleton from 'primevue/skeleton';
import Dialog from 'primevue/dialog';
import { LockPlugin } from '@snapshot-labs/lock/plugins/vue3';
import { createHead } from '@vueuse/head';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';

const pinia = createPinia();
const app = createApp(App)
  .use(i18n)
  .use(router)
  .use(LockPlugin, walletsOptions)
  .use(PrimeVue)
  .use(createHead())
  .use(pinia);

app.component('Button', Button);
app.component('OverlayPanel', OverlayPanel);
app.component('ProgressBar', ProgressBar);
app.component('Skeleton', Skeleton);
app.component('Dialog', Dialog);
app.component('Dropdown', Dropdown);
app.component('Checkbox', Checkbox);

app.mount('#app');
