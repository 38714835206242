import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';

const hasQueryParams = (route: RouteLocationNormalized) => {
  return !!Object.keys(route.query).length;
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/layout/Layout.vue'),
    children: [
      {
        path: '/',
        component: () => import('@/views/pages/welcome/Welcome.vue'),
      },
      {
        path: '/sales',
        component: () => import('@/views/pages/sales/SalesList.vue'),
      },
      {
        path: '/sales/:id',
        component: () => import('@/views/pages/sales/sale/SalePage.vue'),
      },
      {
        path: '/create',
        component: () => import('@/views/pages/create/CreateSale.vue'),
      },
      {
        path: '/create/greeting/:id',
        component: () => import('@/views/pages/create/form-steps/Greeting.vue'),
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/layout/error/Page404.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
    const customNext = () => {
      if (!hasQueryParams(to) && hasQueryParams(from)) {
        return next({ ...to, query: from.query });
      } else {
        return next();
      }
    };

    return customNext();
  },
);

export default router;
